import { RouteObject } from "react-router-dom";
import React from "react";
import AppLayout from "../layouts/app";
import VuiLoadingScreen from "../components/loading-screen";

const SettingCompany = React.lazy(() => import("../pages/setting/company"));
const SettingUserList = React.lazy(() => import("../pages/setting/user/list"));
const SettingUserForm = React.lazy(() => import("../pages/setting/user/form"));
const SettingRoleList = React.lazy(() => import("../pages/setting/role/list"));
const SettingRoleForm = React.lazy(() => import("../pages/setting/role/form"));
const PageList = React.lazy(() => import("../pages/page/list"));
const PageForm = React.lazy(() => import("../pages/page/form"));
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Profile = React.lazy(() => import("../pages/profile"));
const ChangePassword = React.lazy(() => import("../pages/change-password"));
const ProductCategoryList = React.lazy(
  () => import("../pages/product/category/list")
);
const ProductCategoryForm = React.lazy(
  () => import("../pages/product/category/form")
);
const StoreList = React.lazy(() => import("../pages/store/store/list"));
const StoreForm = React.lazy(() => import("../pages/store/store/form"));
const StoreCategoryList = React.lazy(
  () => import("../pages/store/category/list")
);
const StoreCategoryForm = React.lazy(
  () => import("../pages/store/category/form")
);
const ProductSeasonList = React.lazy(
  () => import("../pages/product/season/list")
);
const ProductSeasonForm = React.lazy(
  () => import("../pages/product/season/form")
);
const ProductList = React.lazy(() => import("../pages/product/product/list"));
const ProductForm = React.lazy(() => import("../pages/product/product/form"));

export const appRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Dashboard />
          </React.Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Profile />
          </React.Suspense>
        ),
      },
      {
        path: "change-password",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ChangePassword />
          </React.Suspense>
        ),
      },
      {
        path: "page",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageList />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageForm />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "store",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <StoreList />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <StoreForm />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <StoreForm />
              </React.Suspense>
            ),
          },
          {
            path: "category",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <StoreCategoryList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <StoreCategoryForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <StoreCategoryForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProductList />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProductForm />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProductForm />
              </React.Suspense>
            ),
          },
          {
            path: "season",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductSeasonList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductSeasonForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductSeasonForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "category",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductCategoryList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductCategoryForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductCategoryForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "setting",
        children: [
          {
            path: "company",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <SettingCompany />
              </React.Suspense>
            ),
          },
          {
            path: "user",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingUserList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingUserForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingUserForm />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "role",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingRoleList />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingRoleForm />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <SettingRoleForm />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];
