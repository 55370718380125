import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../context/auth";
import VuiLoadingScreen from "../../components/loading-screen";
import React from "react";

const AuthLayout = () => {
  const { state } = useAuth();

  if (state.phase === "loading") {
    return <VuiLoadingScreen height="100vh" />;
  }

  if (state.phase === "auth" && state.user) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <section id={"auth-layout"}>
      <div className="container-wrapper">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
