import { TFunction } from "react-i18next";
import { ReactNode } from "react";
import VuiDashboardIcon from "../assets/icons/dashboard";
import VuiPageIcon from "../assets/icons/page";
import VuiProductIcon from "../assets/icons/product";
import VuiSettingIcon from "../assets/icons/setting";
import VuiContentIcon from "../assets/icons/content";

export type MenuDataType = {
  title: string;
  key: string;
  access: string | string[];
  icon?: ReactNode;
  children?: MenuDataType[];
};

export const generateMenu = (t: TFunction): MenuDataType[] => {
  return [
    {
      title: t("common.text.dashboard"),
      key: "/",
      access: "any",
      icon: <VuiDashboardIcon />,
    },
    {
      title: t("common.text.pages"),
      key: "/page",
      access: "pages.index",
      icon: <VuiPageIcon />,
    },
    {
      title: t("common.text.store"),
      key: "/store-tab",
      access: ["stores.index", "store-categories.index"],
      icon: <VuiContentIcon />,
      children: [
        {
          title: t("common.text.category"),
          key: "/store/category",
          access: "store-categories.index",
        },
        {
          title: t("common.text.list"),
          key: "/store",
          access: "stores.index",
        },
      ],
    },
    {
      title: t("common.text.product"),
      key: "/product-tab",
      access: ["seasons.index", "categories.index", "products.index"],
      icon: <VuiProductIcon />,
      children: [
        {
          title: t("common.text.season"),
          key: "/product/season",
          access: "seasons.index",
        },
        {
          title: t("common.text.category"),
          key: "/product/category",
          access: "categories.index",
        },
        {
          title: t("common.text.list"),
          key: "/product",
          access: "products.index",
        },
      ],
    },
    {
      title: t("common.text.setting"),
      key: "/setting",
      access: ["settings.show", "users.index", "roles.index"],
      icon: <VuiSettingIcon />,
      children: [
        {
          title: t("common.text.company"),
          key: "/setting/company",
          access: "settings.show",
        },
        {
          title: t("common.text.user"),
          key: "/setting/user",
          access: "users.index",
        },
        {
          title: t("common.text.role"),
          key: "/setting/role",
          access: "roles.index",
        },
      ],
    },
  ];
};
