import { RouteObject } from "react-router-dom";
import React from "react";
import { appRoutes } from "./app";
import { authRoutes } from "./auth";
import VuiLoadingScreen from "../components/loading-screen";

const NotFound = React.lazy(() => import("../pages/not-found"));

export const routes: RouteObject[] = [
  ...appRoutes,
  ...authRoutes,
  {
    path: "*",
    element: (
      <React.Suspense fallback={<VuiLoadingScreen />}>
        <NotFound />
      </React.Suspense>
    ),
  },
];
