export const ENV = {
  getTinyApiKey: () => window._env_.REACT_APP_TINY_API_KEY,
  getApiEndPoint: () => window._env_.REACT_APP_PASSPORT_END_POINT,
  getPassportClientId: () => window._env_.REACT_APP_PASSPORT_CLIENT_ID,
  getPassportClientSecret: () => window._env_.REACT_APP_PASSPORT_CLIENT_SECRET,
  getHostAppUrl: () => window._env_.REACT_APP_HOST_APP_URL,
  getAppName: () => window._env_.REACT_APP_NAME,
};

export const LOCALSTORAGE_FILTER_DATA_KEY = {
  seasons: "filter:seasons",
  categories: "filter:categories",
  status: "filter:status",
  products: "filter:products",
  sources: "filter:sources",
};
