import { ActionMap } from "../../utils/interfaces/action-map.interface";
import { AuthState } from "./index";
import { User } from "../../models/user";

export enum AuthActionType {
  FetchingUser = "FETCHING_USER",
  Login = "LOGIN",
  Logout = "LOGOUT",
  UpdateProfile = "UPDATE_PROFILE",
}

type AuthActionPayload = {
  [AuthActionType.FetchingUser]: undefined;
  [AuthActionType.Login]: User;
  [AuthActionType.Logout]: undefined;
  [AuthActionType.UpdateProfile]: User;
};

export type AuthAction =
  ActionMap<AuthActionPayload>[keyof ActionMap<AuthActionPayload>];

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionType.FetchingUser:
      return {
        ...state,
        phase: "loading",
      };
    case AuthActionType.Login:
      return {
        ...state,
        phase: "auth",
        user: action.payload,
      };
    case AuthActionType.Logout:
      return {
        ...state,
        phase: "no-auth",
        user: null,
      };
    case AuthActionType.UpdateProfile:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
