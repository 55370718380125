import { IBreadcrumb } from "./index";
import { ActionMap } from "../../utils/interfaces/action-map.interface";

export enum BreadcrumbActionType {
  Update = "UPDATE_BREADCRUMB",
}

type BreadcrumbActionPayload = {
  [BreadcrumbActionType.Update]: IBreadcrumb[];
};

export type BreadcrumbAction =
  ActionMap<BreadcrumbActionPayload>[keyof ActionMap<BreadcrumbActionPayload>];

export const breadcrumbReducer = (
  state: IBreadcrumb[],
  action: BreadcrumbAction
) => {
  switch (action.type) {
    case BreadcrumbActionType.Update:
      return [...action.payload];
    default:
      return state;
  }
};
