import React from "react";

const VuiContentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.83333 2C2.82233 2 2 2.82233 2 3.83333V12.1667C2 13.1777 2.82233 14 3.83333 14H10.8021L9.80208 13H3.83333C3.374 13 3 12.626 3 12.1667V3.83333C3 3.374 3.374 3 3.83333 3H12.1667C12.626 3 13 3.374 13 3.83333V9.80208L14 10.8021V3.83333C14 2.82233 13.1777 2 12.1667 2H3.83333ZM4.5 5.33333C4.43374 5.3324 4.36796 5.34464 4.30648 5.36934C4.24499 5.39405 4.18903 5.43073 4.14185 5.47725C4.09466 5.52378 4.0572 5.57921 4.03162 5.64034C4.00605 5.70147 3.99288 5.76707 3.99288 5.83333C3.99288 5.8996 4.00605 5.9652 4.03162 6.02633C4.0572 6.08746 4.09466 6.14289 4.14185 6.18941C4.18903 6.23594 4.24499 6.27262 4.30648 6.29732C4.36796 6.32203 4.43374 6.33427 4.5 6.33333H11.5C11.5663 6.33427 11.632 6.32203 11.6935 6.29732C11.755 6.27262 11.811 6.23594 11.8581 6.18941C11.9053 6.14289 11.9428 6.08746 11.9684 6.02633C11.994 5.9652 12.0071 5.8996 12.0071 5.83333C12.0071 5.76707 11.994 5.70147 11.9684 5.64034C11.9428 5.57921 11.9053 5.52378 11.8581 5.47725C11.811 5.43073 11.755 5.39405 11.6935 5.36934C11.632 5.34464 11.5663 5.3324 11.5 5.33333H4.5ZM4.5 7.66667C4.22367 7.66667 4 7.89033 4 8.16667C4 8.443 4.22367 8.66667 4.5 8.66667H8.68555C8.90488 8.452 9.19235 8.33302 9.49935 8.33268C9.66635 8.33268 9.93685 8.40495 9.93685 8.40495C9.97552 8.33395 10 8.25367 10 8.16667C10 7.89033 9.77633 7.66667 9.5 7.66667H4.5ZM9.48958 9C9.36235 9.0025 9.23898 9.05398 9.14648 9.14648C9.02348 9.26948 8.97332 9.44785 9.01432 9.61719L9.50651 11.6576C9.52784 11.7472 9.57432 11.8289 9.63932 11.8939L12.9453 15.1999C13.256 15.5105 13.6639 15.666 14.0723 15.666C14.4803 15.666 14.8892 15.5102 15.1999 15.1999C15.8215 14.5779 15.8215 13.567 15.1999 12.9453L11.8939 9.63932C11.8289 9.57432 11.7472 9.52784 11.6576 9.50651L9.61719 9.01432C9.57494 9.00399 9.53199 8.99917 9.48958 9ZM4.5 10C4.22367 10 4 10.2237 4 10.5C4 10.7763 4.22367 11 4.5 11H8.66146L8.42057 10H4.5Z"
      fill="black"
    />
  </svg>
);

export default VuiContentIcon;
